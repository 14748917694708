
.dark {
    color: #eee;
}

.dark .main {
    background-color: #222;
    background-image: -webkit-linear-gradient(60deg, #222 50%, #252525 50%);
}

.dark .colourModeButton {
    background-color: #333;
    border: 1px solid #eee;
    color: #eee;
}

.dark .search-container input {
    background-color: #2b2a33;
    color: #eee;
}
.dark .search-msg {
    background-color: rgba(41, 45, 47, 0.86);
}

.dark .bottom-links-section a {
    color: #ccc;
}

.dark .update_link h2, .dark .update_link h3, .dark .small_update_link {
    color: rgb(118, 223, 213);
  }

 .dark .sub-features a, .dark .sub-features-small a, .dark .title-section a  {
    color: rgb(118, 223, 213);
  }

  .dark footer a, .dark footer a:visited {    
    color: rgb(118, 223, 213);
  }

  .dark .red, .dark .updates_header {
    color: #d44060;
  }

  .dark .sub-features i, .dark .sub-features-small i {
    color: #aaa;
  }

  .dark .character_link, .dark .character_link_example, .dark .character_link:visited {
    color: #eee;
    background-color: #333;
  }

  .dark .character_link {
    border-color: #87192a;
  }
  .dark .character_link:hover, .dark .character_link:visited:hover {
    color: #eee;
    background-color: #cd223d;
  }

  .dark .top-details, .dark .radical-link {
    color: #d44060;
  }
  .dark .active {
    color: #eee;
  }

  .dark .filters-button{
    color: #ccc;
  }

  .dark .character_section {
    background-color: #333;
  }

  .dark .radical-button {
    background-color: #333;
    color: #eee;
  }

  .dark .radical-button:hover {
    background-color: #fff;
    color: #cd223d;
  }

  .dark .details_top a {
    color: rgb(118, 223, 213);
  }

  .dark .update_section {
    background-color: #333;
  }

  .dark .update_section a {
    color: rgb(118, 223, 213);
  }
  .dark .update_section a:visited {
    color: #bb44ff;
  }

  
