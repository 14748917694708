 /*font-family: 'Open Sans', sans-serif;
    font-family: 'ZCOOL XiaoWei', serif;
    font-family: 'Noto Sans TC', sans-serif;*/


/* Added from jimjamblog - 2023 */
.colourModeButton {
    display: block;
    width: 2.5em;
    height: 2.5em;
    font-size: larger;
    border-radius: 100%;
    text-decoration: none;
    display: inline-block;
    border: none;
    transition: background-color 0.15s, color 0.15s;
    border: 1px solid #333;
    background-color: #eee;
  }

  .colourModeButtonFooter {
    margin-bottom: 40px;
    align-self: flex-end;
  }
    
  .unlinkStyle {
    text-decoration: none;
    color: inherit;
  }

  /* Updates */
    .update_link h2, .update_link h3, .small_update_link {
        color: #20897f;
        list-style-position: inside;
        margin-top: 5px;
    }  

    .updates_header {
        margin-top: 1em;
        color: #b5183a;
    }

    .update-pagination-btn {
        border: none;
        color: #b5183a;
        background: none;
        font-size: 1em;
        font-weight: 600;
    }

    .update_section {
        background-color: #ffffff;
        min-height: 70vh;
        padding: 2em 3em;
        border-bottom: 1px solid #80808047;
        border-right: 1px solid #80808047;
        border-radius: 10px;
    }

    .update_section img {
        max-width: 100%;
    }

    .update_section li {
        list-style-position: inside;
        list-style-type: square;
        margin-bottom: 10px;
    }

    .updates-container {
        margin: 2em;
    }

/* ------ */

.fa-cloud-download-alt {
    align-self: center;
}
/* ------ */
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
   
    body {
        /* font-family: Calibri, 'Trebuchet MS', sans-serif; */
        font-family: 'Noto Sans TC', 'Open Sans', sans;
        margin: 0;
        min-width: 295px;
    }
   
    .spinner{
       display: flex;
       justify-content: center;
       align-items: center;
       padding: 3em;
    }


/* HOMEPAGE ONLY */
    #top-of-site-pixel-anchor {
        position: absolute;
        width: 1px;
        height: 1px;
        top: 500px;
        left: 0;
    }

    .homepage {
        background-image: linear-gradient(rgba(0, 0, 0, 0.35), rgba(12, 12, 12, 0.73)), url("/public/bk.webp");
        background-attachment: fixed;
        background-size: cover;
        background-position: center;
    }

   
   /* ==============================
        Main Section
       ==============================*/
   
    .main {
        padding: 3em 10em;
        background-image: -webkit-linear-gradient(60deg, #f8f8f8 50%, #f3f3f3 50%);
        min-height: 100vh;
    }
   
    @media screen and (max-width: 975px) {
       /* .sub-features{
           flex-direction: column;
       } */
    }
   
    .main hr {
        border: 0;
        border-top: 1px solid #bebebe;
        margin: 4em 0;
    }
   
    .main-section {
       padding: 2em;
       justify-content: center;
       display: flex;
       flex-direction: column;
       margin: auto;
       align-items: center;    
    }
   
    .character_link, .character_link_example, .character_link:visited {
       background-color: white;
       text-align: center;
       border: 2px #0000004d solid;
       border-radius: 10px;
       transition: 0.2s;
       color: #222222;
       text-decoration: none;
       /* width: 100%; */
       width: 215px;
       /* height: 100%; */
       height: 215px;
       margin: auto;
       overflow: hidden;
       display: flex;
       flex-direction: column;
       justify-content: space-between;
   }
   .currentSearchHanzi{
       box-shadow:#7adef7 0 0 10px;
       border: 2px solid #acd4dd !important;
   }
   .newCharLink{
       box-shadow:#89f77a 0 0 10px;
       border: 2px solid #aeddac !important;
   }
   .character_link:hover {
       background-color: #cd223d;
       color: white;
   }
   
   /* for the radicals and trad/simp indicator */
   .character_link:hover .top-details{
       color: white;
   }
   
   .characters_container {
       display: grid;
       grid-template-columns: repeat(auto-fill, minmax(200px,1fr));
       grid-gap: 1rem;
   }
   
   
   @media screen and (max-width: 1350px) {
       .main {
           padding: 2em;
       }
    }
   
   /* ==============================
        Homepage 
       ==============================*/
   
       /*landing*/
       .landing-area {
           
           min-height: 70vh;
           padding-bottom: 10vh;
           text-align: center;
           color: white;
           display: flex;
           flex-direction: column;
           align-items: center;
           justify-content: center;
       }
   
       .character_link_example{
           min-width: 322px;
           min-height: 322px;
           font-size: 1.5rem;
           margin: 0;
       }
   
   
       .search-container {
           width: 100%;
       }
   
       .search-container form {
           display: flex;
           justify-content: center;
       }
   
       .search-container input {
           padding: 1em;
           margin: 0;
           border: 0;
           border-radius: 1em 0 0 0;
           font-size: 1.25em;
           width: 100%;
           outline: none;
           opacity: 0.9;
       }
   
       .search-container input:focus{
           background-color: #fff;
           opacity: 1;
       }
   
       .search-container button {
           padding: 1em;
           margin: 0;
           border: 0;
           float: right;
           border-radius: 0 1em 1em 0;
           color: #fff;
           background-color: #B5183A;
           font-size: 1.25em;
   
       }
   
       .search-msg {
           opacity: 0;
           background-color: #373737db;
           width: 40%;
           padding: 0.5em 1em;
           border-radius: 0 0 1em 1em;
           text-align: initial;
           transition: 0.4s;
           transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
       }
       .search-container:hover .search-msg{
           opacity: 1;
       }
       .search-container input:focus, .search-msg{
           opacity: 1;
       }
   
      
   
       .landing-area-content {
           max-width: 650px;
           width: 100%;
           margin-bottom: 4em;
       }
   
       .landing-area-content h1 {
           margin-bottom: 1rem;
           font-size: 7em;
           font-weight: 100;
           font-family: 'Open Sans';
       }
   
       .landing-area-content p {
           margin-bottom: 1em;
           font-size: 1.5em;
           font-weight: 300;
       }
   
       /*Character Stat Section*/
       .home-section{
           display: flex;
           flex-direction: row;
           max-width: 1200px;
           justify-content: space-between;
           margin: 4em auto;
           padding: 2em;
           /* align-items: center; */
       }
       .reverse-section{
           flex-direction: row-reverse
       }
       .red{
           color: #b5183a;
           font-size: 4rem;
       }
   
       .nihao{
           display: flex;
           flex-direction: column;
           align-items: center;
           
       }
       .nihao h2{
           display: none;
       }
       .nihao div{  
           height: 300px;
           width: 300px;
           padding: 5px;  
           overflow: hidden;
       }
   
       .title-section {
           align-self: flex-start;
           padding: 25px;
           max-width: 60%;
       }
       .title-section h2{
           font-weight: 100;
       }
       .title-section p{
           font-size: 1.25rem;
       }
   
       /* Sub-feature */
   
       .sub-features-small {
           display: none;
       }
   
       .sub-features{
           padding-top: 3em;
           display: grid;
           grid-template-columns: 25% 25% 25%;
           width: 100%;
           text-align: center;
           align-items: baseline;
           justify-content: space-evenly;
       }
       .sub-features a, .sub-features-small a, .title-section a{
           color: #20897f;
       text-decoration: none;
       }
       .sub-features i, .sub-features-small i{
           color: #333;
       }
       .sub-features-title{
           text-align: center;
           padding: 25px;
           font-weight: 100;
       }
   
       .feature-icon{
           padding: 0.5em 0;
           font-size: 5em;
       }
       .feature-icon-large{
           font-size: 13rem;
           color:#b5183a;
       }
       .feature-title{
           padding: 0 0.25em;
       }
       .feature-text{
           padding: 0 0.25em;
           text-align: justify
       }
   
       .feature{
           padding: 2em;
           text-align: center
       }
       .feature i{
           font-size: 5em;
           padding: 0.5em;
       }
       .feature h2{
           font-size: 2em;
       }
   
   
       .bottom-links-section{
           padding: 1em;
           display: flex;
           margin: 3em 1em;
           flex-direction: column;
           align-items: baseline;
       }
       .bottom-links-section a{
           color:#333;
           text-decoration: none;
           padding: 1em;
           letter-spacing: 1px;
           margin: 1em;
           display: block;
       }
   
       .bottom-links-section a:hover i{
           color: #333;
       }
   
       @media screen and (max-width: 970px) {
           .character_link_example{
               font-size: 1rem;
               min-width: 215px;
               min-height: 215px;
           }
           .title-section{
               max-width: 70%;
               padding: 0 50px 50px 50px; 
           }
           .red{
               font-size: 3rem;
           }
       }
   
       @media screen and (max-width: 700px) {
        .fa-cloud-download-alt {
            display: none;
        }

           .title-section{
               max-width: 100%;
               padding: 0; 
               padding-bottom: 1em; 
           }
           .landing-area-content h1 {
               font-size: 20vw;
           }
   
           .home-section{
               flex-direction: column;
               text-align: center;

           align-items: center;
           }
           .nihao .stat-title{
               display: initial;
               font-size: 2rem;
           }
           .red{
               font-size: 2rem;
           }
   
           .title-section {
               padding-left: 0px;
           }
           
           .stat-title{
               display: none;
           }
   
           .sub-features{
               display: none;
           }
           .sub-features-small{
               display: flex;
               flex-direction: column;
           }
           .search-container input{
               width: 80%;
               border-bottom-left-radius: 10px;
           }
           .search-msg{
               display: none;
           }
           .bottom-links-section a{
           font-size: 1rem;
               margin: 0;
           }
       }
   
       @media screen and (max-width: 360px) {
           .character_link_example{
               width: 125px;
               height: 125px;
               font-size: 0.5rem;
               min-width: 0;
               min-height: 0;
           }
           .character_link_example .bottom-details{
               font-size: inherit;
           }
   
   
       }
   
   
   /* ==============================
        Character 
       ==============================*/
   
    .character {
       font-size: 4em;
       font-weight: 100;
       font-family: "Noto Sans SC", "Noto Sans TC";
   }
   
   .newCharAdded {
       padding: 1em;
       background-color: #b8f28b;
       color: #468400;
       border: 1px solid #dfdfdf;
       margin: 0em auto 1em;
       text-align: center;
       font-weight: 700;
       font-size: 1.25em;
       border-radius: 10px;
       max-width: 700px;
   }
   
   .failedRequests {
       padding: 1em;
       background-color: #f28b8b;
       color: #840000;
       border: 1px solid #dfdfdf;
       margin: 0em auto 1em;
       text-align: center;
       font-weight: 700;
       font-size: 1.25em;
       border-radius: 10px;
       max-width: 700px;
   }
   
   .translations {}
   
   .details {
       height: 1.75em;
       display: flex;
       width: 100%;
       padding: 0 1em;
       align-items: center;
   }
   .top-details {
       color: #cd223d;
       font-weight: bold;
       justify-content: space-between;
       padding-top: 1em;
   }
   .active {
       background-color: #cd223d;
       color: #fff;
       justify-content: center;
       padding: 0;
   }
   
   .bottom-details{
       justify-content: end;
       font-size: 0.75rem;
   
   }
   
   .pinyin {
   
   }
   
   .noResults{
       text-align: center;
       padding: 3em 1em;
       font-size: 2rem;
   }
   
   /* ==============================
        Filters
       ==============================*/
   .top-section{
       display: flex;
       flex-direction: column;
       margin: 1em 0 3em 0;
   }
   
   .filter-arrow{
       transition: 0.2s;
   }
   
   /* The filter section */
   .filter-section{
       background-color: #3f3f3f;
       color: #fff;
       padding: 1em;
       display: flex;
       height: 115px;
       justify-content: space-evenly;
       
       transition: 0.4s;
       transition-timing-function: cubic-bezier(.68,-0.55,.27,1.55);
   
       letter-spacing: 1px;
       text-transform: uppercase;
       line-height: 2em;
   }
   .filter-section * {
       opacity: 1;
       transition: 0.5s;   
   }
   
   /* filter section when invisible */
   .filter-section-invisible{
       height: 0;
       padding-top: 0;
       padding-bottom: 0;
       overflow: hidden;
   }
   .filter-section-invisible *{
     opacity: 0;
   }
   
   .filter-section-checkbox {
       width: 3em;
       height: 3em;
       background: #fff;
       appearance: none;
       -moz-appearance: none;
       border: 1px solid #ccc;
       display: flex;
       justify-content: center;
       align-items: center;
       outline: none;
       transition: 0.3s;
   }
   
   .filter-section-checkbox:checked{
       border-color: #fff;
       background-color: #cd223d;
       border-width: 7px;
   }
   /* .filter-section-checkbox::before{
       font-family: "Font Awesome 5 Free";
       content: "\f45c";
       font-weight: 900; 
       opacity: 0;
       transition: 0.3s;
   }
   .filter-section-checkbox:checked:before {
       opacity: 0;
   } */
   
   .filter-select{
       min-width: 250px;
       color: #222;
   }
   
   .filters-button{
       text-transform: uppercase;
       background: none;
       border: none;
       font-size: 1.5rem;
       align-self: flex-start;
       margin-bottom: 1em;
       transition: 0.5s;
       font-weight: 700;
       letter-spacing: 2px;
       cursor: pointer;
   }
   
   .total{
       text-align: right;
   }
   
   
   @media screen and (max-width: 1075px) {
       .filter-section{
          height: 500px;
          justify-content: space-between;
          flex-direction: column;
       }
       .filter-section-invisible{
           height: 0;
       }
   }
   
   
   /* ==============================
        Pagination
       ==============================*/
   
   .pagination {
       padding-top: 2em;
       list-style: none;
       display: flex;
       justify-content: center;
       align-items: baseline;
       flex-wrap: wrap;
   }
   
   .pagination a,
   .pagination span {
       color: #222222;
       padding: 1em 0.5em;
       font-size: 2rem;
       text-decoration: none;
   }
   
   /* The active page */
   .pagination .active {
       text-decoration: underline;
       font-weight: bold;
   }
   
   .page-item.disabled{
       display: none;
   }
   
   .page-item {
       margin-bottom: 1em;
   }
   
   
   /* ==============================
        Footer
       ==============================*/
   
   footer{
       display: flex;
       justify-content: space-between;
       align-items: flex-end;
       padding: 1em;
   }
   
   .footer-div{
       display: flex;
       padding: 1em 0;
       flex-direction: column;
       align-items: flex-start;
   
   }
   
   footer a, footer a:visited{
       color: #20897f;
       text-decoration: none;
       margin: 0.2em 0;
   }
   
   footer h2{
       padding: 1em 0;
   }
   
   @media screen and (max-width: 500px) {
       footer{
           flex-direction: column;
           align-items: center;
       }

    .colourModeButtonFooter {
        margin: 40px 0;
        align-self: center;
      }
   
       .footer-div{
           align-items: center;
       }
   
       footer a{
           margin: 0.5em 0;
           padding: 0.25em;
       }
   
   }
   
   
   
   
   
   
   
   
   /* ==============================
        TopBar
       ==============================*/
   
       .topbar {
           width: 100%;
           background: none;
           color: white;
           display: flex;
           justify-content: space-between;
           align-items: center;
           border: none;
           position: fixed;
           z-index: 5;
           height: 100px;
       }
       
       /* topbar-clear is used to provide padding for top of page (as topbar is fixed) */
       .topbar-clear{
           height: 100px;
       }
   
       
       /* ==============================
        logo container for the TopBar
       ==============================*/
       .logo-container-top{
           display: flex;
           color: white;
           align-items: center;
           text-decoration: none;
           font-family: inherit;
           font-weight: 700;
           padding-right: 1em;
       }
       /* styles for the logo label */
       .logo-container-top span{
           font-size: 1.75em;
           font-weight: 500;
           font-family: 'open sans';
       }
   
       /* styles for the logo image */
       .logo-container-top img {
           margin: 1em;
           height: 5em;
       }
       
   
       /* ==============================
        links container for the TopBar
       ==============================*/
       .links-container-top {
           padding: 1em;
           display: flex;
       }
       .links-container-top a {
           color: white;
           height: auto;
           font-size: 1.5em;
           padding: 0 1em;
   
           text-decoration: none;
       }
       .links-container-top a:hover {
           color: #cd223d;
       }
   
   
       /* ==============================
        bMenu (burgerMenu for mobile)
       ==============================*/
       .hamburger-button{
           display: none;
           color: white;
           background: none;
           border: none;
           padding: 1em;
           font-size: 2rem;
       }
       
       /* The menu, fixed and covers the screen */
       .bMenu{
           position: fixed;
           height: 100%;
           background-color:#555555e6;
           top: 0;
           width: 100vw;
           display: flex;
           align-items: center;
           justify-content: space-evenly;
           padding-top: 100px;
           z-index: 3;
       }
   
       /* Container for the links within bMenu (provides padding) */
       .bMenu-container{
           display: flex;
           flex-direction: column;
           align-items: center;
       }
   
       /* The bMenu link styles */
       .bMenu a{
           color: white;
           text-decoration: none;
            padding: 0.75em; 
           font-size: 1.5rem;
       }
   
       /* bMenu link to my website */
       .bMenu-bottom-link{
           padding-top: 2em;
       }
       .bMenu-bottom-link a{
           color: cyan;
           font-size: 1rem;
       }
   
       /* When closed don't display */
       .bMenuClosed{
           display: none;
       }
   
   
       /* ==============================
        Search Container (topbar)
       ==============================*/
       .search-container-top {
           width: 100%;
           margin: auto 1em;
           display: none;
       }
       
       /* this is used to keep the search button next to the input */
       .search-container-top form {
           display: flex;
       }
   
       .search-container-top input {
           padding: 0.5em 1em;
           margin: 0;
           border: 0;
           border-radius: 1em 0 0 1em;
           font-size: 1.25em;
           width: 100%;
           outline: none;
           min-width: 100px;
           max-width: 300px;
       }
   
       .search-container-top button {
           padding: 0.5em 1em;
           margin: 0;
           border: 0;
           float: right;
           border-radius: 0 1em 1em 0;
           color: #fff;
           font-size: 1.25em;
           background-color: #ffffff29;
       }
   
   
   
   
   
       .text-red {
           color: #cd223d;
       }
       .text-white {
           color: #fff !important;
       }
       .text-black {
           color: #222 !important;
       }
   
   
   /* ==============================
        character single 
       ==============================*/

       .radical-header{
        width: 100%;
        background-color: #c82929;
        border-radius: 10px 10px 0% 0%;
        color: #fff;
        padding: 1em 2em;
        font-size: 1.5rem;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
    .character_section {
        background-color: #ffffff;
        min-height: 70vh;
        padding: 2em 3em;
        border-bottom: 1px solid #80808047;
        border-right: 1px solid #80808047;
        border-radius: 10px;
        display: flex;
    }
    .character_section_radical{
        border-radius: 0 0 10px 10px;
    }

    .character_type{
        margin: 1em;
    }

    .character_small, .character_type_small, .pinyin_small{
        display: none;
    }

    .details_container {
        width: 100%;
    }

    .details_section {
        width: 100%;
    }
    .details_top {
        display: flex;
        justify-content: space-between;
        
    }
    .details_title{
        display: inline-flex;
    align-items: baseline;
    }
    .details_top a {
        color: #227a7a;
        text-decoration: none;
    }
    .left_section {

        text-align: center;
    }
    .freq {
        margin: 1em;
        color: white;
        font-weight: 500;
        font-family: Open Sans;
        display: inline-block;
        padding: 7px 8px;
        background: linear-gradient(#d5d5d5, #898989);
        /*border: 2px solid #cecece6b;*/
    }
    .freq-1 {
        background: linear-gradient(#84d968, #28953a);
    }
    .freq-2 {
        background: linear-gradient(#68cfd9, #289595);
    }
    .freq-3 {
        background: linear-gradient(#8689f8, #2328c0);
    }
    .freq-4 {
        background: linear-gradient(#fab98c, #ca7538);
    }
    .freq-5 {
        background: linear-gradient(#fa8c8c, #ca3838);
    }
    .translations, .heisig{
        margin: 1em;
    }
    .translation_title{
        font-size: 2em;
        
    }
    
    .translation_text{
        font-size: 1.25em;
        list-style: lower-roman;
        list-style-position: inside;
    }
    .translation_text li{
        margin-top: 1em;
    }

    #character-target-div{
        font-family: "Noto Serif SC", "Noto Serif TC";
        font-size: 20vw;
        font-weight: 100;
        margin-right: 3rem;
        color: #c82929;
    }

    .radical-link {
        color: #c82929;
        background: none;
        border: none;
        cursor: pointer;
    }

    .radical-button {
        color: #c82929;
        background-color: #fff;
        padding: 1em;
        text-transform: uppercase;
        border: none;
        cursor: pointer;
        transition: 0.2s;
        transition-timing-function: cubic-bezier(1,0,0,1);
        text-decoration: none;
        font-size: 14px;
    }
    .radical-button:hover{
        color: #fff;
        background-color: #c82929;
    }

    @media screen and (max-width: 1350px) {
        /* Do not display the large character stuff */
       .details_title .character, .details_title .character_type {
           display: none;
       }


       .character_small, .character_type_small, .pinyin_small{
           display: initial;
       }

       .character_small{
           font-size: 6rem;
           font-weight: 400;
       }

       
       .translation_title{
           font-size: 1.25rem;
       }
       .translation_text{
           font-size: 1rem;
       }

       /* Used to align the top details to the right and in column formation, instead of row */
       .details_top, .details_title{
        display: flex;
        flex-direction: column;
        text-align: right;
        align-items: end;
       }
       .translations, .heisig{
           text-align: right;
       }

       /* Pushes the character and pinyin to the left a little to be aligned with the links */
       .details_title{
        padding: 0 1em;
       }

       /* the links section styles */
       .similar_characters{
           margin: 1.5em;
       }
       .similar_characters form, .similar_characters a h3{
           margin-top: 1em; 
       }
    }

    @media screen and (max-width: 675px) {
        .character_section{
            flex-direction: column-reverse;
        }
        .details_top, .details_title{
            text-align: center;
            align-items: center;
        }
        .translations, .heisig{
           text-align: initial;
       }
    }
    @media screen and (max-width: 675px) {
        .main{
            padding: 1em 0;
        }


        /*Radical header*/
        .radical-button {
            margin-top: 2.5em;
        }
        .radical-header{
            align-items: center;
            flex-direction: column;
        }
    }
   
   
       /* ========= MEDIA QUERIES ========= */
      
   
       /* ==============================
        Medium screens
       ==============================*/
       @media screen and (max-width: 825px) {
           .links-container-top {
               display : none;
           }
           .logo-search-container{
               width: 100%;
           }
           .logo-container-top span {
               display: none;
           }
           .hamburger-button{
               display: inherit;
           }
       }
   
       /* Used to disable the view of the bMenu if screen is big */
       @media screen and (min-width: 825px) {
           .bMenu{
               display: none;
           }
       }
   
       /* ==============================
        Small screens
       ==============================*/
       @media screen and (max-width: 625px) {
   
           /* Topbar */
           .search-container-top {
               margin: auto;
           }
           .logo-search-container a{
               padding-right: none;
           }
           .search-container-top input{
               width: 100px;
           }
           .topbar img {
               margin: 1em 0 1em 1em;
           }
           .search-container-top button{
               display: none;
           }
           .search-container-top input{
               border-radius: 1em;
               width: 50%;
           }
           .search-container-top form{
               justify-content: center;
           }
   
           /* newCharAdded */
   
           .newCharAdded{
               margin: 0em 0em 1em;
               font-size: 1rem;
           }
       }
   
   
   /* ==============================
       History Page
   ==============================*/
   
   .history th{
       padding: 1em;
   }
   .history-character a{
       color: #333;
       font-size: 2.5rem;
       text-decoration: none;
   }